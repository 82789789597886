@import "~bootstrap/scss/functions";
@import '~bootstrap/scss/variables';

.loading-data-overlay {
    position: fixed; /* Sit on top of the page content */
    display: flex; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($secondary, .75);
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fadein .6s;
  }

  @keyframes fadein {
    0% { opacity: 0; }
    25% { opacity: .1; }
    75% { opacity: .9; }
    100%   { opacity: 1; }
}

  .loading-heading {
    color: white;
    font-size: 1.25em;
    font-weight: bold;
  }

  .loading-subheading {
    color: white;
  }