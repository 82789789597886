@import 'variables';

@import '~bootstrap/scss/bootstrap';

@import 'components/NavigationButtons/NavigationButtons';
@import 'components/Loading/LoadingData';

@import 'pages/DemographicInformation/DateOfBirth';
@import 'pages/BenefitAmount/BenefitSelection';
@import 'pages/Status/Status';
@import 'pages/Terms/Terms';
@import 'pages/Documents/SignDocuments';
  
  @keyframes slideInRight {
    from {
        opacity: 0;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to { 
        opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes slideOutLeft {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  
    to {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes slideOutRight {
    from {
        opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  
    to {
        opacity: 0;
      visibility: hidden;
      transform: translate3d(100%, 0, 0);
    }
  }
  
  @keyframes slideInLeft {
    from {
        opacity: 0;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }
  
    to {
        opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .page-transition-enter-active{
    animation-name: slideInRight;
    animation-duration: 300ms;
    pointer-events: none;
  }

  .page-transition-exit-active{
    animation-name: slideOutLeft;
    animation-duration: 350ms;
    pointer-events: none;
  }
  .page-going-back{
    .page-transition-enter-active{
        animation-name: slideInLeft;
      }
    
      .page-transition-exit-active{
        animation-name: slideOutRight;
      }
  }

  .modal-full {
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-full .modal-content {
      min-height: calc(100vh - 56px);
  }

  .faq-button {
    visibility: hidden;
    pointer-events: none;
    &:hover{
      cursor: pointer;
    }
  }

  .btn-inline{
    @extend .p-0;
    @extend .m-0;
    border-width: 0;
    font-size: inherit;
    vertical-align: inherit;
  }

  .navbar{
    box-shadow: 0 1px 2px rgba(0,0,0,.04);
  }