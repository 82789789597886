.table-details td{
    border-color: $primary;
    border-width: 1px;
    border-style: solid;
    background-color: $white;
    font-weight: 400;
    border-width: 2px;
}

.full-screen-object{
    min-height: 225px;
    max-height: calc(100vh - 285px);
}

.text-dark-blue {
    color: #00598C;    
}

.top-margin-20 {
    margin-top: 20px;
}

.header {
    font-size: 1.35rem;    
}

.italics {
    font-style: italic;
}