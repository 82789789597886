@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

body{
    padding-bottom: 40px;
}

.app-button-nav{
    display: flex;
    align-content: stretch;
    button{
        border-radius: 0;
        &:last-child{
            flex-grow: 0;
            min-width: 125px;
        }
        &:first-child{
            flex-grow: 1;
        }
        img, div{
            height: 1em;
            width: 1em;
        }

        div{
            display: inline-block;
        }
    }
}